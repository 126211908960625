/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Grid,
  Image,
  LoadingOverlay,
  Paper,
  RingProgress,
  Stack,
  Text,
  Title,
  useMantineTheme
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { ThemeContext } from "../contexts/ThemeContext";
import "./typewriter.css";

type TypeWriterProps = {
  texts: string[];
  images: string[];
  typeSpeed?: number;
  loop?: boolean;
  ereaseSpeed?: number;
  duration?: number;
  className?: string;
  onChange?: Function;
};

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

enum State {
  type,
  erease,
  complete,
}

export default function TypeWriter({
  texts,
  typeSpeed = 10,
  className,
  loop = false,
  images,
}: TypeWriterProps) {
  const [current, setCurrent] = useState("");
  const [index, setIndex] = useState(0);
  const [status, setStatus] = useState(State.type);
  const [buttonTimer, setButtonTimer] = useState(0);
  const { width } = useViewportSize();
  const language = useContext(LanguageContext);
  const { colorScheme } = useContext(ThemeContext);
  const isDark = colorScheme === 'dark';
  const theme = useMantineTheme();

  useEffect(() => {
    if (status === State.complete && loop) {
      const interval = setInterval(() => {
        setButtonTimer((prev) => {
          if (prev < 100) {
            return prev + 1;
          } else {
            clearInterval(interval);
            indexHandler(); // Auto-click the button
            return prev;
          }
        });
      }, 120); // Adjust timing (e.g., 100ms increments)
      return () => clearInterval(interval); // Cleanup on component unmount or dependency change
    }
    if (!loop) {
      setButtonTimer(0);
    }
  }, [status, loop]);

  if (status === State.type) {
    type(texts[index]);
  }

  function currentHandler(text: string, length: number) {
    setCurrent(text.slice(0, length));
  }

  function indexHandler() {
    setButtonTimer(0);
    let temp = index;
    if (index < texts.length - 1) {
      setIndex(temp + 1);
    } else {
      setIndex(0);
    }
    setCurrent("");
    setStatus(State.type);
  }

  function type(text: string) {
    if (current.length < text.length) {
      sleep(500 / typeSpeed).then(() => {
        currentHandler(text, current.length + 1);
      });
    } else {
      setStatus(State.complete);
    }
  }

  return (
    <Grid justify="center" align="center" gutter={40}>
      <Grid.Col display={"flex"} sx={{ justifyContent: "center" }} md={6}>
        <Box 
          component={motion.div}
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
          pos="relative" 
          sx={{ 
            width: "100%", 
            maxWidth: 450,
            borderRadius: theme.radius.md,
            overflow: "hidden",
            boxShadow: isDark 
              ? "0 10px 30px -10px rgba(0,0,0,0.5)" 
              : "0 10px 30px -10px rgba(0,0,0,0.2)",
          }}
        >
          <LoadingOverlay
            visible={status !== State.complete}
            color={isDark ? "dark" : "teal"}
            overlayBlur={Math.floor((texts[index].length / (current.length === 0 ? 1 : current.length))*5)}
            loader={
              <RingProgress
                size={80}
                thickness={8}
                sections={[
                  {
                    value: (current.length / texts[index].length) * 100,
                    color: "#0ca678",
                  },
                ]}
                label={
                  <Text color={isDark ? "white" : "dark"} align="center" size="sm" weight={700}>
                    {Math.round((current.length / texts[index].length) * 100)}%
                  </Text>
                }
              />
            }
          />
          <Image
            sx={{ 
              mih: { base: 300, sm: 350, md: 400 },
              backgroundColor: isDark ? "black" : "white",
              transition: "all 0.3s ease",
            }}
            width="100%"
            height={400}
            src={images[index]}
            fit="cover"
          />
        </Box>
      </Grid.Col>

      <Grid.Col md={6}>
        <Stack spacing="md" sx={width < 992 ? { minHeight: 180 } : {}}>
          <Paper
            p="md"
            radius="md"
            sx={{
              background: isDark ? "rgba(20, 20, 20, 0.8)" : "rgba(255, 255, 255, 0.8)",
              backdropFilter: "blur(10px)",
              border: `1px solid ${isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
              boxShadow: isDark ? "0 4px 20px rgba(0,0,0,0.4)" : "0 4px 20px rgba(0,0,0,0.1)",
            }}
          >
            <Title
              ta="left"
              order={width > 600 ? 2 : 3}
              fw={700}
              color={isDark ? "white" : "#333"}
              sx={{
                textShadow: isDark ? "0 2px 4px rgba(0,0,0,0.5)" : "none",
              }}
            >
              <Text className={className} color={isDark ? "white" : "#333"}>
                {status === State.complete ? texts[index] : current}
                <span className="blinkingCursor">|</span>
              </Text>
            </Title>
          </Paper>
          
          {status === State.complete ? (
            <Box 
              component={motion.div} 
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
            >
              <Button
                pl={25}
                pr={25}
                size={width < 600 ? "sm" : "md"}
                sx={{
                  width: "fit-content",
                  borderRadius: 100,
                  color: "white",
                  borderColor: isDark ? "rgba(255, 255, 255, 0.3)" : "#0ca678",
                  background: isDark 
                    ? `linear-gradient(90deg, #2d3748 ${buttonTimer}%, #1a202c ${buttonTimer+1}%)` 
                    : `linear-gradient(90deg, #0ca678 ${buttonTimer}%, #099268 ${buttonTimer+1}%)`,
                  transition: "background 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease",
                  "&:hover": {
                    transform: "translateY(-2px)",
                    backgroundColor: isDark ? "#2d3748" : "#099268",
                    boxShadow: isDark 
                      ? "0 4px 12px rgba(0, 0, 0, 0.3)" 
                      : "0 4px 12px rgba(12, 166, 120, 0.3)",
                  },
                  "&:active": {
                    transform: "translateY(1px)",
                  }
                }}
                onClick={() => {
                  indexHandler();
                }}
              >
                {language.strings.typewriterNextButton}
              </Button>
            </Box>
          ) : null}
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
