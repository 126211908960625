import { MantineProvider } from "@mantine/core";
import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { LanguageProvider } from "./contexts/LanguageContext";
import { ThemeContext, ThemeProvider } from './contexts/ThemeContext';
import FourOhFour from "./pages/404/fourohfour";
import Home from "./pages/home/home";

function AppContent() {
  const { theme } = useContext(ThemeContext);

  return (
    <MantineProvider
      theme={{
        colorScheme: theme.colorScheme,
        primaryColor: 'teal',
        primaryShade: { light: 6, dark: 8 },
        colors: {
          teal: [
            '#e6fcf5',
            '#c3fae8',
            '#96f2d7',
            '#63e6be',
            '#38d9a9',
            '#20c997',
            '#0ca678',
            '#099268',
            '#087f5b',
            '#066649',
          ],
        },
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <LanguageProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index element={<Home/>} />
              <Route path="*" element={<FourOhFour/>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </LanguageProvider>
    </MantineProvider>
  );
}

export default function App() {
  return (
    <ThemeProvider>
      <AppContent />
    </ThemeProvider>
  );
}
