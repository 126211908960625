// Portfolio data file to make content management easier
import image1 from "../images/image1.png";
import image2 from "../images/image2.png";
import image3 from "../images/image3.png";
import image4 from "../images/image4.png";
import image5 from "../images/image5.png";
import image6 from "../images/image6.png";
import project1 from "../images/project1.png";
import project2 from "../images/project2.png";
import project3 from "../images/project3.png";
import project4 from "../images/project4.png";
import project5 from "../images/project5.png";
import project6 from "../images/project6.png";
import project7 from "../images/project7.png";
import project8 from "../images/project8.png";
import project9 from "../images/project9.png";
import project10 from "../images/project10.png";
import project11 from "../images/project11.png";
import project12 from "../images/project12.jpg";


import {
  IconBrandAws,
  IconBrandGithub,
  IconBrandJavascript,
  IconBrandMysql,
  IconBrandNextjs,
  IconBrandReactNative,
  IconBrandTypescript,
} from "@tabler/icons-react";

// This allows you to easily update your portfolio content in one place
export const portfolioData = {
  // Banner images for the typewriter component
  bannerImages: [image1, image2, image3, image4, image5, image6],

  // Skills section - easy to add/remove skills
  skills: [
    {
      icon: IconBrandJavascript,
      name: "Node.js",
      color: "#68a063",
      descriptionKey: "descriptionNodejs"
    },
    {
      icon: IconBrandNextjs,
      name: "Next.js",
      color: "white", // Using teal color for consistency
      descriptionKey: "descriptionNextjs"
    },
    {
      icon: IconBrandReactNative,
      name: "React",
      color: "#61dbfb",
      descriptionKey: "descriptionReact"
    },
    {
      icon: IconBrandMysql,
      name: "MySQL",
      color: "#00758f",
      descriptionKey: "descriptionMysql"
    },
    {
      icon: IconBrandAws,
      name: "AWS",
      color: "#FF9900",
      descriptionKey: "descriptionAws"
    },
    {
      icon: IconBrandGithub,
      name: "GitHub",
      color: "white", // Using a neutral color that works in both themes
      descriptionKey: "descriptionGithub"
    },
    {
      icon: IconBrandTypescript,
      name: "Typescript",
      color: "#007acc",
      descriptionKey: "descriptionTypescript"
    },
  ],
  
  // Project images mapping
  projectImages: {
    project1, project2, project3, project4, project5, project6, project7,
    project8, project9, project10, project11, project12
  }
}; 