import { createContext, useState, useEffect, ReactNode } from 'react';
import { MantineThemeOverride } from '@mantine/core';

type ThemeContextType = {
  colorScheme: 'dark' | 'light';
  toggleColorScheme: () => void;
  theme: MantineThemeOverride;
};

const defaultTheme: ThemeContextType = {
  colorScheme: 'dark',
  toggleColorScheme: () => {},
  theme: {
    colorScheme: 'dark',
    colors: {
      dark: [
        '#C1C2C5',
        '#A6A7AB',
        '#909296',
        '#5c5f66',
        '#373A40',
        '#2C2E33',
        '#25262b',
        '#1A1B1E',
        '#141517',
        '#101113',
      ],
      blue: [
        '#e6f7ff',
        '#bae7ff',
        '#91d5ff',
        '#69c0ff',
        '#40a9ff',
        '#1890ff',
        '#096dd9',
        '#0050b3',
        '#003a8c',
        '#002766',
      ],
    },
    primaryColor: 'dark',
  },
};

export const ThemeContext = createContext<ThemeContextType>(defaultTheme);

interface ThemeProviderProps {
  children: ReactNode;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  const [colorScheme, setColorScheme] = useState<'dark' | 'light'>(
    () => (localStorage.getItem('theme') as 'dark' | 'light') || 'dark'
  );

  const toggleColorScheme = () => {
    const newColorScheme = colorScheme === 'dark' ? 'light' : 'dark';
    setColorScheme(newColorScheme);
    localStorage.setItem('theme', newColorScheme);
  };

  useEffect(() => {
    // Apply theme to document body
    document.body.classList.remove('light-theme', 'dark-theme');
    document.body.classList.add(`${colorScheme}-theme`);
  }, [colorScheme]);

  const theme: MantineThemeOverride = {
    colorScheme,
    colors: {
      dark: [
        '#C1C2C5',
        '#A6A7AB',
        '#909296',
        '#5c5f66',
        '#373A40',
        '#2C2E33',
        '#25262b',
        '#1A1B1E',
        '#141517',
        '#101113',
      ],
      blue: [
        '#e6f7ff',
        '#bae7ff',
        '#91d5ff',
        '#69c0ff',
        '#40a9ff',
        '#1890ff',
        '#096dd9',
        '#0050b3',
        '#003a8c',
        '#002766',
      ],
    },
    primaryColor: colorScheme === 'dark' ? 'blue' : 'dark',
    components: {
      Container: {
        defaultProps: {
          sizes: {
            xs: 540,
            sm: 720,
            md: 960,
            lg: 1140,
            xl: 1320,
          },
        },
      },
    },
  };

  return (
    <ThemeContext.Provider value={{ colorScheme, toggleColorScheme, theme }}>
      {children}
    </ThemeContext.Provider>
  );
} 