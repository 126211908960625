import { Box, Paper, Text, Title } from "@mantine/core";
import { ReactNode, useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

interface SkillButtonProps {
  icon: ReactNode;
  name: string;
  color: string;
  description: string;
}

export default function SkillButton({
  icon,
  name,
  color,
  description,
}: SkillButtonProps) {
  const { colorScheme } = useContext(ThemeContext);
  const isDark = colorScheme === 'dark';
  let iconColor = color;
  
  if (name === "GitHub" || name === "Next.js") {
    iconColor = isDark ? "white" : "black";
  }

  return (
    <Paper
      p="md"
      w={"100%"}
      radius="md"
      sx={{
        width: "100%",
        height: "100%",
        minHeight: 200,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        background: isDark 
          ? "rgba(25, 25, 25, 0.8)" 
          : "rgba(255, 255, 255, 0.9)",
        border: `1px solid ${isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
        transition: "all 0.3s ease",
        "&:hover": {
          cursor: "default",
          transform: "translateY(-5px)",
          boxShadow: `0 0px 20px 0px ${iconColor}` 
        },
      }}
    >
      <Box
        sx={{
          color: color,
          marginBottom: 10,
          transition: "transform 0.3s ease, color 0.2s ease",
          "&:hover": {
            transform: "scale(1.1)",
            color: "#0ca678",
          },
        }}
      >
        {icon}
      </Box>
      
      <Title order={4} align="center" mb={10} color={isDark ? "white" : "#333"}>
        {name}
      </Title>
      
      <Text 
        size="sm" 
        align="center" 
        color={isDark ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)"}
        sx={{ lineHeight: 1.5 }}
      >
        {description}
      </Text>
    </Paper>
  );
}
