/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useViewportSize, useWindowScroll } from "@mantine/hooks";
import { format as dateFormat, format, parseISO, subYears } from 'date-fns';
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import TypeWriter from "../../components/typewriter";
import { portfolioData } from "../../data/portfolioData";

import {
  ActionIcon,
  Affix,
  Badge,
  Box,
  Container,
  Flex,
  Grid,
  Group,
  Loader,
  Paper,
  rem,
  Select,
  Stack,
  Text,
  Title,
  Tooltip,
  Transition
} from "@mantine/core";
import {
  IconArrowUp,
  IconRepeat,
} from "@tabler/icons-react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import PDTimeline from "../../components/pdtimeline";
import ProjectCard from "../../components/projectcard";
import SkillButton from "../../components/skillbutton";
import { LanguageContext } from "../../contexts/LanguageContext";
import { ThemeContext } from "../../contexts/ThemeContext";
import "./home.css";

// Define ProjectType inline
interface ProjectType {
  title: string;
  description: string;
  techs: string[];
  githublink?: string;
  linkedinlink?: string;
  websitelink?: string;
  createdAt: string;
  lang: string;
}

// Add these interfaces inside your file, before the Home component
interface GitHubStats {
  totalContributions: number;
  highlightedInfo: {
    stars: number;
    repos: number;
    followers: number;
  };
}

// Add this interface before the Home component
interface ContributionDay {
  date: string;
  contributionCount: number;
}

// Update the interfaces at the top of the file
interface GraphQLResponse {
  data: {
    user: {
      contributionsCollection: {
        contributionCalendar: {
          totalContributions: number;
          weeks: {
            contributionDays: {
              contributionCount: number;
              date: string;
            }[];
          }[];
        };
      };
    };
  };
  errors?: Array<{ message: string }>;
}

// Simple animation variants
const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

// Add this function near the top of the file, after imports
const localizeDate = (date: string, lang: string) => {
  const monthTranslations: { [key: string]: { en: string; tr: string } } = {
    'Jan': { en: 'Jan', tr: 'Oca' },
    'Feb': { en: 'Feb', tr: 'Şub' },
    'Mar': { en: 'Mar', tr: 'Mar' },
    'Apr': { en: 'Apr', tr: 'Nis' },
    'May': { en: 'May', tr: 'May' },
    'Jun': { en: 'Jun', tr: 'Haz' },
    'Jul': { en: 'Jul', tr: 'Tem' },
    'Aug': { en: 'Aug', tr: 'Ağu' },
    'Sep': { en: 'Sep', tr: 'Eyl' },
    'Oct': { en: 'Oct', tr: 'Eki' },
    'Nov': { en: 'Nov', tr: 'Kas' },
    'Dec': { en: 'Dec', tr: 'Ara' }
  };

  const formattedDate = format(parseISO(date), 'MMM d, yyyy');
  if (lang === 'tr') {
    return formattedDate.replace(/[A-Za-z]+/g, (month) => 
      monthTranslations[month]?.tr || month
    );
  }
  return formattedDate;
};

// Update the fetchYearlyContributions function
async function fetchYearlyContributions(from: string, to: string, language: any): Promise<GitHubContributions> {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const token = process.env.REACT_APP_GITHUB_TOKEN;
  
  if (!token) {
    throw new Error(language.strings.tokenNotFound);
  }

  const query = `
    query {
      user(login: "tscburak") {
        contributionsCollection(from: "${from}", to: "${to}") {
          contributionCalendar {
            totalContributions
            weeks {
              contributionDays {
                contributionCount
                date
              }
            }
          }
        }
      }
    }
  `;

  const response = await fetch('https://api.github.com/graphql', {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query }),
  });

  if (!response.ok) {
    throw new Error(language.strings.failedToFetch);
  }

  const data = await response.json() as GraphQLResponse;
  
  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  const calendar = data.data.user.contributionsCollection.contributionCalendar;
  const days: ContributionDay[] = [];
  
  calendar.weeks.forEach(week => {
    week.contributionDays.forEach(day => {
      days.push({
        date: day.date,
        contributionCount: day.contributionCount
      });
    });
  });

  return {
    totalContributions: calendar.totalContributions,
    contributionDays: days,
    isLoading: false,
    error: null
  };
}

// Update the GitHubContributions interface
interface GitHubContributions {
  totalContributions: number;
  contributionDays: ContributionDay[];
  isLoading: boolean;
  error: string | null;
}

// Add this helper function in your Home component
const getContributionColor = (count: number, isDark: boolean) => {
  if (count === 0) return isDark ? '#2d333b' : '#ebedf0';
  if (count <= 3) return isDark ? '#0e4429' : '#9be9a8';
  if (count <= 6) return isDark ? '#006d32' : '#40c463';
  if (count <= 9) return isDark ? '#26a641' : '#30a14e';
  return isDark ? '#39d353' : '#216e39';
};


export default function Home() {
  const { width } = useViewportSize();
  const [scroll, scrollTo] = useWindowScroll();
  const [speed, setSpeed] = useState(1);
  const [loop, setLoop] = useState(false);
  const language = useContext(LanguageContext);
  const { colorScheme } = useContext(ThemeContext);
  const isDark = colorScheme === 'dark';

  // Map skills with descriptions from language context and apply theme-specific colors
  const skills = portfolioData.skills.map(skill => {
    // Apply theme-specific colors for certain icons
    let iconColor = skill.color;
    
    // Special handling for GitHub icon
    if (skill.name === "GitHub" || skill.name === "Next.js") {
      iconColor = isDark ? "white" : "black";
    }
    
    return {
      icon: <skill.icon size={50} color={iconColor} />,
      name: skill.name,
      color: skill.color,
      description: String(language.strings[skill.descriptionKey as keyof typeof language.strings])
    };
  });

  // Map projects with images from the data file
  const projects = language.strings.contentProjects.map((project: ProjectType, index: number) => {
    const imageKey = `project${12 - index}` as keyof typeof portfolioData.projectImages;
    return {
      ...project,
      img: portfolioData.projectImages[imageKey]
    };
  });

  // In your Home component, add these state variables
  const today = new Date();
  const defaultFromDate = subYears(today, 1);

  const [dateRange, setDateRange] = useState({
    from: defaultFromDate,
    to: today
  });
  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [contributions, setContributions] = useState<GitHubContributions>({
    totalContributions: 0,
    contributionDays: [],
    isLoading: false,
    error: null
  });

  // Update the useEffect in your Home component:
  useEffect(() => {
    const fetchContributions = async () => {
      setContributions(prev => ({ ...prev, isLoading: true, error: null }));
      
      try {
        let fromDate: string;
        let toDate: string;

        if (selectedYear) {
          // If a specific year is selected
          fromDate = `${selectedYear}-01-01T00:00:00Z`;
          toDate = `${selectedYear}-12-31T23:59:59Z`;
        } else {
          // Use the rolling year date range
          fromDate = dateFormat(dateRange.from, "yyyy-MM-dd'T'HH:mm:ss'Z'");
          toDate = dateFormat(dateRange.to, "yyyy-MM-dd'T'HH:mm:ss'Z'");
        }

        const contributionsData = await fetchYearlyContributions(fromDate, toDate, language);
        setContributions(contributionsData);
      } catch (error) {
        setContributions({
          totalContributions: 0,
          contributionDays: [],
          isLoading: false,
          error: error instanceof Error ? error.message : 'Failed to fetch contributions'
        });
      }
    };

    fetchContributions();
  }, [selectedYear, dateRange]);


  return (
    <>
      <Stack spacing={0} bg={isDark ? "black" : "#f8f9fa"}>
        <Header />
        
        {/* Hero Section */}
        <Box
          pt={"90px"}
          sx={{
            minHeight: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            pl={"8vw"}
            pr={"8vw"}
            sx={{ display: "flex", justifyContent: "end" }}
          >
            <Group>
              <Tooltip label={language.strings.generationRate} openDelay={500}>
                <Flex gap={2}>
                  <ActionIcon
                    sx={{ width: "fit-content" }}
                    onClick={() => setSpeed(0.25)}
                    radius={0}
                    variant={speed === 0.25 ? "filled" : "outline"}
                    color={isDark ? "gray" : "teal"}
                  >
                    <Text pr={2} pl={2} size={"xs"}>0.25x</Text>
                  </ActionIcon>
                  <ActionIcon
                    sx={{ width: "fit-content" }}
                    onClick={() => setSpeed(1)}
                    radius={0}
                    variant={speed === 1 ? "filled" : "outline"}
                    color={isDark ? "gray" : "teal"}
                  >
                    <Text pr={2} pl={2} size={"xs"}>1x</Text>
                  </ActionIcon>
                  <ActionIcon
                    sx={{ width: "fit-content" }}
                    onClick={() => setSpeed(2)}
                    radius={0}
                    variant={speed === 2 ? "filled" : "outline"}
                    color={isDark ? "gray" : "teal"}
                  >
                    <Text pr={2} pl={2} size={"xs"}>2x</Text>
                  </ActionIcon>
                </Flex>
              </Tooltip>

              <Tooltip label={language.strings.loop} openDelay={500}>
                <ActionIcon
                  sx={{ width: "fit-content" }}
                  onClick={() => setLoop((prev) => !prev)}
                  radius={0}
                  variant={loop ? "filled" : "outline"}
                  color={isDark ? "gray" : "teal"}
                >
                  <IconRepeat stroke={1.5} />
                </ActionIcon>
              </Tooltip>
            </Group>
          </Box>
          
          <Box pl={"5vw"} pr={"5vw"} pt={"xl"}>
            <TypeWriter
              typeSpeed={speed * 10}
              ereaseSpeed={speed * 20}
              texts={language.strings.typewriterTexts}
              images={portfolioData.bannerImages}
              loop={loop}
            />
          </Box>
        </Box>

        {/* About Section */}
        <Container size="lg" py={60} id="about">
          <Box component={motion.div} {...fadeInUp}>
            <Title
              align="center"
              sx={{ color: isDark ? "white" : "#333", marginBottom: 30 }}
              order={1}
            >
              {language.strings.headerAbout}
            </Title>
            
            <Paper 
              p="xl" 
              radius="md" 
              sx={{ 
                background: isDark ? "rgba(25, 25, 25, 0.8)" : "rgba(255, 255, 255, 0.9)",
                border: `1px solid ${isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
                boxShadow: isDark ? "0 8px 20px rgba(0,0,0,0.3)" : "0 8px 20px rgba(0,0,0,0.1)",
                //maxWidth: 800,
                margin: "0 auto"
              }}
            >
              <Text 
                size="md" 
                color={isDark ? "rgba(255,255,255,0.9)" : "rgba(0,0,0,0.8)"}
                sx={{ lineHeight: 1.8 }}
              >
                {language.strings.contentAbout}
              </Text>
            </Paper>
          </Box>
        </Container>

        <Container size="lg" py={60} id="activity">
            <Title
              align="center"
              sx={{ color: isDark ? "white" : "#333", marginBottom: 30 }}
              order={1}
            >
              {language.strings.headerActivity}
            </Title>
            {/* Add GitHub Contributions Section */}
            <Paper
              mt="xl"
              p="xl"
              radius="md"
              sx={{
                background: isDark ? "rgba(25, 25, 25, 0.8)" : "rgba(255, 255, 255, 0.9)",
                border: `1px solid ${isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
                boxShadow: isDark ? "0 8px 20px rgba(0,0,0,0.3)" : "0 8px 20px rgba(0,0,0,0.1)",
                margin: "0 auto"
              }}
            >
              <Flex justify="space-between" gap={"xl"} mb="md" direction={{ base: "column", md: "row" }}>
                <Flex gap="xl" direction="column" align="flex-start">
                  <Badge
                    size="lg"
                    radius="sm"
                    variant="dot"
                    sx={{
                      background: isDark ? "rgba(60, 60, 60, 0.8)" : "rgba(12, 166, 120, 0.1)",
                      color: isDark ? "white" : "#0ca678",
                    }}
                  >
                    {contributions.isLoading ? (
                      <Group spacing="xs">
                        <Loader size="xs" color={isDark ? "white" : "teal"} />
                        <Text size="sm">{language.strings.loading}</Text>
                      </Group>
                    ) : (
                      <Text size="sm" weight={700}>
                        {selectedYear 
                          ? language.strings.contributionsInYear
                              .replace('{count}', contributions.totalContributions.toString())
                              .replace('{year}', selectedYear)
                          : language.strings.contributionsCount
                              .replace('{count}', contributions.totalContributions.toString())
                        }
                      </Text>
                    )}
                  </Badge>
                  
                  {/* Add date range badge when showing rolling year */}
                  {!selectedYear && !contributions.isLoading && (
                    <Badge
                      size="sm"
                      radius="sm"
                      variant="light"
                      sx={{
                        background: isDark ? "rgba(40, 40, 40, 0.8)" : "rgba(12, 166, 120, 0.05)",
                        color: isDark ? "rgba(255, 255, 255, 0.8)" : "#0ca678",
                      }}
                    >
                      <Text size="xs">
                        {localizeDate(dateFormat(dateRange.from, "yyyy-MM-dd'T'HH:mm:ss'Z'"), language.languageCode)}
                        {" - "}
                        {localizeDate(dateFormat(dateRange.to, "yyyy-MM-dd'T'HH:mm:ss'Z'"), language.languageCode)}
                      </Text>
                    </Badge>
                  )}
                </Flex>
                <Select
                  value={selectedYear || ''}
                  onChange={(value) => setSelectedYear(value)}
                  data={[
                    { value: '', label: language.strings.lastTwelveMonths },
                    { value: '2025', label: '2025' },
                    { value: '2024', label: '2024' },
                    { value: '2023', label: '2023' },
                    { value: '2022', label: '2022' },
                    { value: '2021', label: '2021' }
                  ]}
                  sx={{ width: 150 }}
                  disabled={contributions.isLoading}
                  placeholder={language.strings.selectPeriod}
                />
              </Flex>

              {contributions.error ? (
                <Badge color="red" size="lg" radius="sm" variant="filled">
                  {contributions.error}
                </Badge>
              ) : (
                <Box w={"100%"} sx={{width: "100%", opacity: contributions.isLoading ? 0.7 : 1 }}>
                  {contributions.isLoading ? (
                    <Flex align="center" justify="center" h={200}>
                      <Loader size="xl" color={isDark ? "white" : "teal"} />
                    </Flex>
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        //overflowX: 'auto',
                        // Hide scrollbar visually while maintaining functionality
                        '&::-webkit-scrollbar': {
                          display: 'none'
                        },
                        '-ms-overflow-style': 'none',
                        'scrollbarWidth': 'none',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 3,
                          padding: '20px 0',
                          // Set minimum width based on column count to maintain square size
                          //minWidth: width < 500 ? '400px' : 'unset',
                          // Center content
                          margin: '0 auto',
                          // Ensure squares maintain size
                         
                        }}
                      >
                        {contributions.contributionDays.map((day) => (
                          <Tooltip
                            key={day.date}
                            label={language.strings.contributionsOn
                              .replace('{count}', day.contributionCount.toString())
                              .replace('{date}', localizeDate(day.date, language.languageCode))}
                            position="top"
                            withArrow
                          >
                            <Box
                              sx={{
                                width: 10,
                                height: 10,
                                backgroundColor: getContributionColor(day.contributionCount, isDark),
                                borderRadius: 2,
                                transition: 'all 0.2s ease',
                                '&:hover': {
                                  transform: 'scale(1.2)',
                                },
                              }}
                            />
                          </Tooltip>
                        ))}
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </Paper>
            </Container>

        {/* Experiences Section */}
        <Box 
          sx={{ 
            background: isDark 
              ? "linear-gradient(to bottom, rgba(15, 15, 15, 0.8), rgba(10, 10, 10, 0.8))" 
              : "linear-gradient(to bottom, rgba(240, 240, 240, 0.9), rgba(245, 245, 245, 0.9))",
            boxShadow: isDark ? "none" : "inset 0 0 10px rgba(0, 0, 0, 0.05)",
            borderTop: `1px solid ${isDark ? "rgba(50, 50, 50, 0.5)" : "rgba(0, 0, 0, 0.1)"}`,
            borderBottom: `1px solid ${isDark ? "rgba(50, 50, 50, 0.5)" : "rgba(0, 0, 0, 0.1)"}`,
          }} 
          py={60} 
          id="experiences"
        >
          <Container size="lg">
            <Box component={motion.div} {...fadeInUp}>
              <Title
                align="center"
                sx={{ 
                  color: isDark ? "white" : "#333", 
                  marginBottom: 40,
                  //fontWeight: 800,
                  textShadow: isDark ? "0 2px 4px rgba(0,0,0,0.5)" : "0 1px 2px rgba(0,0,0,0.1)",
                }}
                order={1}
              >
                {language.strings.headerExperiences}
              </Title>
              
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <PDTimeline 
                  items={language.strings.contentExperiences} 
                  isDark={isDark} 
                />
              </Box>
            </Box>
          </Container>
        </Box>

        {/* Skills Section */}
        <Container size="lg" py={60} id="skills">
          <Box component={motion.div} {...fadeInUp}>
            <Title
              align="center"
              sx={{ 
                color: isDark ? "white" : "#333", 
                marginBottom: 10 
              }}
              order={1}
            >
              {language.strings.headerSkills}
            </Title>
            
            <Box pb={40} sx={{ display: "flex", justifyContent: "center" }}>
              <Badge 
                color={isDark ? "cyan" : "teal"} 
                size="lg"
                radius="sm"
                variant="filled"
                sx={{ 
                  background: isDark ? "rgba(60, 60, 60, 0.8)" : "rgba(12, 166, 120, 0.1)",
                  color: isDark ? "white" : "#0ca678",
                  fontWeight: 600,
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              >
                {language.strings.subHeaderSkills}
              </Badge>
            </Box>

            <Grid>
              {skills.map((skill) => (
                <Grid.Col
                  key={skill.name + "_grid"}
                  sx={{ display: "flex", justifyContent: "center" }}
                  span={12}
                  w={"100%"}
                  sm={6}
                  md={4}
                  lg={3}
                >
                  <Box w={"100%"} component={motion.div} whileHover={{ y: -5 }} transition={{ duration: 0.2 }}>
                    <SkillButton {...skill} />
                  </Box>
                </Grid.Col>
              ))}
            </Grid>
          </Box>
        </Container>

        {/* Projects Section */}
        <Box 
          sx={{ 
            background: isDark ? "rgba(15, 15, 15, 0.8)" : "rgba(240, 240, 240, 0.9)",
            boxShadow: isDark ? "none" : "inset 0 0 10px rgba(0, 0, 0, 0.05)"
          }} 
          py={60} 
          id="projects"
        >
          <Container size="lg">
            <Box component={motion.div} {...fadeInUp}>
              <Title
                align="center"
                sx={{ 
                  color: isDark ? "white" : "#333", 
                  marginBottom: 10 
                }}
                order={1}
              >
                {language.strings.headerProjects}
              </Title>
              
              <Box pb={40} sx={{ display: "flex", justifyContent: "center" }}>
                <Badge 
                  color={isDark ? "gray" : "teal"} 
                  size="lg"
                  radius="sm"
                  variant="filled"
                  sx={{ 
                    background: isDark ? "rgba(60, 60, 60, 0.6)" : "rgba(12, 166, 120, 0.1)",
                    color: isDark ? "white" : "#0ca678",
                    fontWeight: 600,
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  }}
                >
                  {language.strings.subHeaderProjects}
                </Badge>
              </Box>
              
              <Grid gutter={30}>
                {projects.map((project: ProjectType & { img: string }) => (
                  <Grid.Col
                    key={project.title + "_grid"}
                    sx={{
                      textAlign: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                    sm={6}
                    lg={4}
                  >
                    <Box w={"100%"} 
                    //component={motion.div} whileHover={{ y: -5 }} transition={{ duration: 0.2 }}
                    
              >
                      <ProjectCard {...project} />
                    </Box>
                  </Grid.Col>
                ))}
              </Grid>
            </Box>
          </Container>
        </Box>

        <Footer />
        
        <Affix position={{ bottom: rem(20), right: rem(20) }}>
          <Transition transition="slide-up" mounted={scroll.y > 0}>
            {(transitionStyles) => (
              <ActionIcon
                size={"xl"}
                radius={100}
                variant="filled"
                style={transitionStyles}
                onClick={() => scrollTo({ y: 0 })}
                sx={{
                  background: isDark ? "rgba(0, 0, 0, 0.7)" : "rgba(255, 255, 255, 0.7)",
                  border: `1px solid ${isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
                  color: isDark ? "white" : "#0ca678",
                  "&:hover": {
                    background: isDark ? "rgba(30, 30, 30, 0.9)" : "rgba(240, 240, 240, 0.9)",
                    color: "#099268",
                  }
                }}
              >
                <IconArrowUp />
              </ActionIcon>
            )}
          </Transition>
        </Affix>
      </Stack>
    </>
  );
}


function shuffleArray(array:Array<any>) {
  for (let i = array.length - 1; i >= 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}