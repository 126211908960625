import { createContext, useState, ReactNode } from "react";
import en from "../languages/en.json";
import tr from "../languages/tr.json";

type LanguageContextType = {
  languageCode: string;
  strings: any;
  setLanguage: (lang: string) => void;
};

export const LanguageContext = createContext<LanguageContextType>({
  languageCode: "en",
  strings: en,
  setLanguage: () => {},
});

interface LanguageProviderProps {
  children: ReactNode;
}

export function LanguageProvider({ children }: LanguageProviderProps) {
  let defaultLang = {
    langPack: en,
    langCode: "en",
  };

  if (navigator.language.toLowerCase().includes("tr")) {
    defaultLang = {
      langPack: tr,
      langCode: "tr",
    };
  }

  const [language, setLanguage] = useState(defaultLang);

  function languageHandler(langCode: string, langPack: any) {
    setLanguage({
      langPack: langPack,
      langCode: langCode,
    });
  }

  return (
    <LanguageContext.Provider
      value={{
        languageCode: language.langCode,
        strings: language.langPack,
        setLanguage: (lang: string) => {
          switch (lang) {
            case "tr":
              languageHandler("tr", tr);
              break;
            case "en":
              languageHandler("en", en);
              break;
            default:
              break;
          }
        },
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
} 