/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  Anchor,
  Box,
  Burger,
  Flex,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useViewportSize, useWindowScroll } from "@mantine/hooks";
import {
  IconBrandGithub,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconMail,
  IconMoon,
  IconSun
} from "@tabler/icons-react";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import { ThemeContext } from "../contexts/ThemeContext";

export default function Header() {
  const [_, scrollTo] = useWindowScroll();
  const [active, setActive] = useState(0);
  const { width } = useViewportSize();
  const [menu, setMenu] = useState(false);
  const language = useContext(LanguageContext);
  const { colorScheme, toggleColorScheme } = useContext(ThemeContext);
  const isDark = colorScheme === 'dark';
  
  const langAnchors = () => (
    <>
      <Anchor 
        onClick={()=>{language.setLanguage("tr")}} 
        sx={{
          color: isDark ? "white" : "#333",
          transition: "0.3s",
        }} 
        underline={false}
      >
        {language.languageCode === "tr" ? <u>TR</u> : "TR"}
      </Anchor>
      <Anchor 
        onClick={()=>{language.setLanguage("en")}} 
        sx={{
          color: isDark ? "white" : "#333",
          transition: "0.3s",
        }} 
        underline={false}
      >
        {language.languageCode === "en" ? <u>EN</u> : "EN"}
      </Anchor>
    </>
  );

  const ThemeIcon = () => <ActionIcon
    component={motion.button}
    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.9 }}
    variant="outline"
    color={isDark ? "yellow" : "teal"}
    onClick={toggleColorScheme}
    title={isDark ? "Light mode" : "Dark mode"}
    size="md"
    ml={10}
    sx={{
      borderRadius: "50%",
      transition: "all 0.3s ease",
      boxShadow: isDark 
        ? "0 0 10px rgba(255, 255, 255, 0.1)" 
        : "0 0 10px rgba(0, 0, 0, 0.1)",
    }}
  >
    {isDark ? <IconSun size={16} /> : <IconMoon size={16} />}
  </ActionIcon>

  const anchorList = () => (
    <>
      <Anchor
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
        }}
        underline={false}
        onClick={() => {
          goTo("about");
        }}
      >
        <Text
          sx={{
            color: active === 1 
              ? (isDark ? "teal" : "#0ca678") 
              : (isDark ? "white" : "#333"),
            textDecoration: active === 1 ? "underline" : "none",
            fontWeight: active === 1 ? 700 : 400,
            transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          {language.strings.headerAbout}
        </Text>
      </Anchor>
      <Anchor
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
        }}
        underline={false}
        onClick={() => {
          goTo("activity");
        }}
      >
        <Text
          sx={{
            color: active === 2 
              ? (isDark ? "teal" : "#0ca678") 
              : (isDark ? "white" : "#333"),
            textDecoration: active === 2 ? "underline" : "none",
            fontWeight: active === 2 ? 700 : 400,
            transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          {language.strings.headerActivity}
        </Text>
      </Anchor>
      <Anchor
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
        }}
        underline={false}
        onClick={() => {
          goTo("experiences");
        }}
      >
        <Text
          sx={{
            color: active === 3 
              ? (isDark ? "teal" : "#0ca678") 
              : (isDark ? "white" : "#333"),
            textDecoration: active === 3 ? "underline" : "none",
            fontWeight: active === 3 ? 700 : 400,
            transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          {language.strings.headerExperiences}
        </Text>
      </Anchor>
      <Anchor
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
        }}
        underline={false}
        onClick={() => {
          goTo("skills");
        }}
      >
        <Text
          sx={{
            color: active === 4 
              ? (isDark ? "teal" : "#0ca678") 
              : (isDark ? "white" : "#333"),
            textDecoration: active === 4 ? "underline" : "none",
            fontWeight: active === 4 ? 700 : 400,
            transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          {language.strings.headerSkills}
        </Text>
      </Anchor>
      <Anchor
        sx={{
          paddingLeft: 5,
          paddingRight: 5,
        }}
        underline={false}
        onClick={() => {
          goTo("projects");
        }}
      >
        <Text
          sx={{
            color: active === 5 
              ? (isDark ? "teal" : "#0ca678") 
              : (isDark ? "white" : "#333"),
            textDecoration: active === 5 ? "underline" : "none",
            fontWeight: active === 5 ? 700 : 400,
            transition: "all 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
          }}
        >
          {language.strings.headerProjects}
        </Text>
      </Anchor>
    </>
  );

  const socialLinkList = () => (
    <Group spacing={8}>
      <ActionIcon
        component="a"
        href="https://github.com/tscburak"
        target="_blank"
        variant="transparent"
        sx={{
          color: isDark ? "white" : "#0ca678",
          "&:hover": {
            color: "gray",
          },
        }}
      >
        <IconBrandGithub size={20} />
      </ActionIcon>
      
      <ActionIcon
        component="a"
        href="https://www.linkedin.com/in/burak-tasci-8b34a9176/"
        target="_blank"
        variant="transparent"
        sx={{
          color: isDark ? "white" : "#0ca678",
          "&:hover": {
            color: "#0077B5",
          },
        }}
      >
        <IconBrandLinkedin />
      </ActionIcon>
      
      <ActionIcon
        component="a"
        href="https://www.instagram.com/tscburak/"
        target="_blank"
        variant="transparent"
        sx={{
          color: isDark ? "white" : "#0ca678",
          "&:hover": {
            color: "#E4405F",
          },
        }}
      >
        <IconBrandInstagram />
      </ActionIcon>
      
      <ActionIcon
        component="a"
        href="mailto:burak@tscburak.dev"
        target="_blank"
        variant="transparent"
        sx={{
          color: isDark ? "white" : "#0ca678",
          "&:hover": {
            color: "#4285F4",
          },
        }}
      >
        <IconMail />
      </ActionIcon>
    </Group>
  );
  const listenScrollEvent = () => {
    const headerHeight = window.document.getElementById("header")!.clientHeight;
    
    // Get positions with proper offsets
    const aboutElement = window.document.getElementById("about");
    const activityElement = window.document.getElementById("activity");
    const experiencesElement = window.document.getElementById("experiences");
    const skillsElement = window.document.getElementById("skills");
    const projectsElement = window.document.getElementById("projects");
    
    if (!aboutElement || !activityElement || !experiencesElement || !skillsElement || !projectsElement) return;
    
    // Get the current scroll position
    const currentScrollPos = window.scrollY;
    
    // Get the positions of each section relative to the document
    const aboutPos = aboutElement.offsetTop - headerHeight - 50;
    const activityPos = activityElement.offsetTop - headerHeight - 50;
    const experiencesPos = experiencesElement.offsetTop - headerHeight - 50;
    const skillsPos = skillsElement.offsetTop - headerHeight - 50;
    const projectsPos = projectsElement.offsetTop - headerHeight - 50;
    
    // Get the bottom of the document
    const docHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    
    // Determine which section is currently in view
    if (currentScrollPos < aboutPos) {
      setActive(0); // No section is active
    } else if (currentScrollPos >= aboutPos && currentScrollPos < activityPos) {
      setActive(1); // About section is active
    } else if (currentScrollPos >= activityPos && currentScrollPos < experiencesPos) {
      setActive(2); // Activity section is active
    } else if (currentScrollPos >= experiencesPos && currentScrollPos < skillsPos) {
      setActive(3); // Experiences section is active
    } else if (currentScrollPos >= skillsPos && currentScrollPos < projectsPos) {
      setActive(4); // Skills section is active
    } else if (currentScrollPos >= projectsPos) {
      setActive(5); // Projects section is active
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  function goTo(id: string) {
    const element = window.document.getElementById(id);
    if (!element) return;
    
    const headerHeight = window.document.getElementById("header")!.clientHeight;
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - headerHeight - 20;
    
    scrollTo({ y: offsetPosition });
    
    // Update active state immediately for better UX
    if (id === "about") setActive(1);
    else if (id === "activity") setActive(2);
    else if (id === "experiences") setActive(3);
    else if (id === "skills") setActive(4);
    else if (id === "projects") setActive(5);
  }

  return (
    <Box
      id="header"
      sx={{
        position: "fixed",
        top: 0,
        right: 0,
        zIndex: 1000,
        display: "block",
        width: "100%",
        background: isDark 
          ? "rgba(10, 10, 10, 0.85)" 
          : "rgba(245, 245, 245, 0.9)",
        backdropFilter: "blur(10px)",
        borderBottom: `1px solid ${isDark ? "rgba(50, 50, 50, 0.5)" : "rgba(0, 0, 0, 0.1)"}`,
        boxShadow: isDark 
          ? "0 4px 20px rgba(0, 0, 0, 0.3)" 
          : "0 4px 20px rgba(0, 0, 0, 0.1)",
        transition: "all 0.3s ease",
      }}
    >
      <Flex
        p={"sm"}
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Flex
          sx={{ position: "absolute", left: "8vw" }}
          gap={"sm"}
          direction={"row"}
          align="center"
        >
          {width > 1200 ? (
            <>{anchorList()}</>
          ) : (
            <Burger
              opened={menu}
              onClick={() => {
                const temp = menu;
                setMenu(!temp);
              }}
              color={isDark ? "white" : "#333"}
            ></Burger>
          )}
        </Flex>
        <Stack
          spacing={1}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Title
            order={2}
            variant="gradient"
            ta={"center"}
            gradient={{ 
              from: isDark ? "#e0e0e0" : "#333", 
              to: isDark ? "#a0a0a0" : "#777", 
              deg: 180 
            }}
            sx={{
              textShadow: isDark ? "0 2px 4px rgba(0,0,0,0.5)" : "0 1px 2px rgba(0,0,0,0.2)",
            }}
          >
            tscburak.dev
          </Title>
          <Box display={width>=600 ? "flex" : "none"} sx={{ justifyContent: "center", display: "flex" }}>
            {socialLinkList()}
          </Box>
        </Stack>
        <Group 
          spacing={8} 
          sx={{
            justifyContent: "center", 
            position: "absolute", 
            right: "8vw",
            display: "flex",
            alignItems: "center"
          }} 
          display={width >= 600 ? "flex" : "none"}
        >
          {langAnchors()}
          {ThemeIcon()}
        </Group>
      </Flex>
      <Stack
        display={menu && width <= 1200 ? "flex" : "none"}
        sx={{
          backgroundColor: isDark ? "rgba(15, 15, 15, 0.95)" : "rgba(245, 245, 245, 0.95)", 
          paddingBottom: 20,
          borderBottom: `1px solid ${isDark ? "rgba(50, 50, 50, 0.5)" : "rgba(0, 0, 0, 0.1)"}`,
          boxShadow: isDark ? "0 4px 12px rgba(0,0,0,0.4)" : "0 4px 12px rgba(0,0,0,0.1)",
        }}
      >
        <Stack pt={15} pl={"8vw"}>
          {" "}
          {anchorList()}
        </Stack>
        <Box
          sx={{ justifyContent: "center" }}
          display={width < 600 ? "flex" : "none"}
        >
          {" "}
          {socialLinkList()}
        </Box>
        <Group
          sx={{ justifyContent: "center" }}
          display={width < 600 ? "flex" : "none"}
        >
          {langAnchors()}
          {ThemeIcon()}
        </Group>
      </Stack>
    </Box>
  );
}
