import { Text, Timeline } from "@mantine/core";
import { IconBriefcase } from "@tabler/icons-react";

type PDTimelineItemProps = {
  title: string;
  description: string;
  date: string;
};

type PDTimelineProps = {
  items: PDTimelineItemProps[];
  isDark: boolean;
};

export default function PDTimeline({ items, isDark }: PDTimelineProps) {
  return (
    <Timeline color="white" bulletSize={20} lineWidth={4}>
      {items.map((item, index) => {
        return (
          <Timeline.Item
            key={item.title+"_timeline"}
            bullet={<IconBriefcase size={20} />}
            title={<Text weight={700} color={isDark ? "white" : "dark"}>{item.title}</Text>}
            lineVariant={index === items.length - 1 ? "dashed" : "solid"}
            sx={{
              '& .mantine-Timeline-itemBullet': {
                backgroundColor: isDark ? '#1a1a1a' : '#f8f9fa',
                color: '#0ca678',
                border: `2px solid #0ca678`,
              }
            }}
          >
            <Text color={isDark ? "gray.5" : "gray.7"} size="sm">{item.date}</Text>
            <Text color={isDark ? "gray.3" : "dark"} size="md" mt={4}>{item.description}</Text>
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
}
