/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  ActionIcon,
  Badge,
  Box,
  Card,
  Group,
  Image,
  Text,
  Title,
  Tooltip
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconBrandGithub, IconExternalLink } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { useContext } from "react";
import { ThemeContext } from "../contexts/ThemeContext";

type ProjectCardProps = {
  title: string;
  img?: string;
  description: string;
  techs: string[];
  githublink?: string;
  linkedinlink?: string;
  websitelink?: string;
  createdAt: string;
  lang:string;
};

export default function ProjectCard({
  title,
  img,
  description,
  techs,
  githublink,
  linkedinlink,
  websitelink,
  createdAt,
  lang,
}: ProjectCardProps) {
  const { colorScheme } = useContext(ThemeContext);
  const isDark = colorScheme === 'dark';
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Card
      shadow="md"
      radius="md"
      component={motion.div}
      //whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        background: isDark 
          ? "rgba(25, 25, 25, 0.8)" 
          : "rgba(255, 255, 255, 0.9)",
        border: `1px solid ${isDark ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
        overflow: "hidden",
        transition: "all 0.3s ease",
        "&:hover": {
          boxShadow: isDark 
            ? "0 8px 20px rgba(0, 0, 0, 0.5)" 
            : "0 8px 20px rgba(0, 0, 0, 0.15)",
        },
      }}
    >
      <Card.Section>
        <Box sx={{ position: "relative", overflow: "hidden", height: isMobile ? 150 : 200 }}>
          <Image
            src={img}
            height={isMobile ? 150 : 200}
            alt={title}
            sx={{
              objectFit: "cover",
              transition: "transform 0.5s ease",
              "&:hover": {
                transform: "scale(1.05)",
              },
            }}
          />
          
          {/* Project links as icons on the image */}
          <Group 
            position="right" 
            spacing="xs"
            sx={{ 
              position: "absolute", 
              top: 10, 
              right: 10,
              zIndex: 2
            }}
          >
            {websitelink && (
              <Tooltip label="View Project" position="top">
                <ActionIcon 
                  component={motion.a}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  href={websitelink}
                  target="_blank"
                  variant="filled"
                  radius="xl"
                  size={isMobile ? "md" : "lg"}
                  sx={{
                    backgroundColor: isDark 
                      ? "rgba(0, 0, 0, 0.7)" 
                      : "rgba(255, 255, 255, 0.7)",
                    color: isDark ? "white" : "black",
                    backdropFilter: "blur(5px)",
                    "&:hover": { 
                      backgroundColor: isDark 
                        ? "rgba(30, 30, 30, 0.9)" 
                        : "rgba(240, 240, 240, 0.9)",
                      color: "#0ca678",
                    }
                  }}
                >
                  <IconExternalLink size={isMobile ? 16 : 18} />
                </ActionIcon>
              </Tooltip>
            )}
            
            {githublink && (
              <Tooltip label="GitHub" position="top">
                <ActionIcon 
                  component={motion.a}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  href={githublink}
                  target="_blank"
                  variant="filled"
                  radius="xl"
                  size={isMobile ? "md" : "lg"}
                  sx={{
                    backgroundColor: isDark 
                      ? "rgba(0, 0, 0, 0.7)" 
                      : "rgba(255, 255, 255, 0.7)",
                    color: isDark ? "white" : "#0ca678",
                    backdropFilter: "blur(5px)",
                    "&:hover": { 
                      backgroundColor: isDark 
                        ? "rgba(30, 30, 30, 0.9)" 
                        : "rgba(240, 240, 240, 0.9)",
                      color: "#099268",
                    }
                  }}
                >
                  <IconBrandGithub size={isMobile ? 16 : 18} />
                </ActionIcon>
              </Tooltip>
            )}
          </Group>
        </Box>
      </Card.Section>

      <Box p="md" sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Title 
          order={3} 
          mb={10} 
          color={isDark ? "white" : "#333"} 
          lineClamp={1}
          sx={{
            fontWeight: 700,
            textShadow: isDark ? "0 1px 2px rgba(0,0,0,0.3)" : "none",
          }}
        >
          {title}
        </Title>

        {/* Scrollable description area */}
        <Box 
          sx={{ 
            flex: 1,
            maxHeight: "120px",
            overflowY: "auto",
            marginBottom: 15,
            // Custom scrollbar styling
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              background: isDark ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.05)",
            },
            "&::-webkit-scrollbar-thumb": {
              background: isDark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
              borderRadius: "2px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: isDark ? "rgba(255, 255, 255, 0.3)" : "rgba(0, 0, 0, 0.3)",
            },
          }}
        >
          <Text
            size="sm"
            color={isDark ? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.8)"}
            sx={{ lineHeight: 1.6 }}
          >
            {description}
          </Text>
        </Box>

        <Group position="apart" mt="auto">
          <Box>
            {techs.map((tech) => (
              <Badge
                key={tech}
                mr={5}
                mb={5}
                variant="filled"
                color={isDark ? "dark" : "teal"}
                sx={{ 
                  background: isDark 
                    ? "rgba(60, 60, 60, 0.8)" 
                    : "rgba(12, 166, 120, 0.1)",
                  color: isDark ? "white" : "#0ca678",
                  fontWeight: 500,
                }}
              >
                {tech}
              </Badge>
            ))}
          </Box>
        </Group>
      </Box>
    </Card>
  );
}
